<template>
    <v-dialog v-model="visible" persistent max-width="600px">
        <v-card>
            <v-card-title class="justify-center">
                <v-spacer/>
                <span class="text-h5">{{ form.id ? 'Edição' : 'Cadastro'}} de Conta a Pagar/Receber</span>
                <v-spacer/>
                <v-btn icon @click="$emit('close')">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text align="center" justify="center" style="height: 420px;">
                <!-- <v-text-field
                    label="Nº Pedido"
                    v-model="form.pedido_id"
                    @change="onChangePedido"
                    outlined
                    dense
                    hide-details
                    class="mt-3"
                />
                <CustomerSearchAutocomplete
                    v-model="form.cliente"
                    @update="onClienteUpdate"
                    @reset="cliente = null"
                /> -->
                <v-text-field
                    label="Titulo"
                    v-model="form.titulo"
                    outlined
                    dense
                    hide-details
                    class="mt-3"
                />
                <v-select
                    :items="tipos"
                    v-model="form.tipo"
                    label="Tipo"
                    outlined
                    dense
                    hide-details
                    class="mt-3"
                />
                <v-select
                    :items="status"
                    v-model="form.status"
                    label="Status"
                    outlined
                    dense
                    hide-details
                    class="mt-3"
                />
                <v-text-field
                    label="Valor"
                    v-model="form.valor"
                    prefix="R$"
                    v-mask="['#.##','##.##','###.##','####.##','#####.##']"
                    outlined
                    dense
                    hide-details
                    class="mt-3"
                />
                <v-text-field
                    label="Vencimento"
                    v-model="form.vencimento"
                    type="date"
                    outlined
                    dense
                    hide-details
                    class="mt-3"
                />
                <v-textarea
                    v-model="form.observacao"
                    clear-icon="mdi-backspace-outline"
                    label="Observacao"
                    dense
                    hide-details
                    clearable
                    outlined
                    class="mt-3"
                />
            </v-card-text>
            <v-card-actions>
                <v-btn text color="warning" @click="$emit('close')">Cancelar</v-btn>
                <v-spacer />
                <v-btn color="success" @click="salvar" :loading="loading">
                    <v-icon>mdi-content-save</v-icon>&nbsp;Salvar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
// import CustomerSearchAutocomplete from '@/pages/home/Pedido/CustomerSearchAutocomplete';

export default {
    name: 'ContaPagarReceber',

    components: {
        // CustomerSearchAutocomplete,
    },

    props: {
        visible: {
            type: Boolean,
            default: false
        },
        formData: {
            type: Object,
            default: Object
        },
    },

    data: () => ({
        loading: false,
        form: {
            // pedido_id: '',
            // cliente_id: '',
            observacao: '',
            tipo: 'pagar',
        },
        tipos: [
            {
                text: 'Pagar',
                value: 'pagar',
            },
            {
                text: 'Receber',
                value: 'receber',
            },
        ],
        status: [
            {
                text: 'Pendente',
                value: 'pendente',
            },
            {
                text: 'Vencido',
                value: 'vencido',
            },
            {
                text: 'Pago',
                value: 'pago',
            },
        ],
    }),

    mounted() {
        this.form = this.formData;
    },

    watch: {
        formData(value) {
            this.form = value ? JSON.parse(JSON.stringify(value)) : {};
        },
    },

    methods: {
        salvar() {
            if (!this.form.titulo) {
                this.notify('Informe o titulo', 'warning');
                return;
            }
            if (!this.form.tipo) {
                this.notify('Informe o tipo de conta', 'warning');
                return;
            }
            if (!this.form.valor) {
                this.notify('Informe o valor', 'warning');
                return;
            }

            const id = this.form.id;

            if (id) {
                this.loading = true;
                this.$http.put(`contas-pagar-receber/${id}`, this.form).then(resp => {
                    const data = resp.data;
                    if (data.type == 'warning') {
                        this.notify(data.msg, 'warning');
                        return;
                    }
                    this.$emit('onCadastro');
                    this.notify('Atualizado com sucesso!');
                }).catch(() => {
                    this.notify('Verifique sua conexão com a internet', 'warning');
                }).finally(() => (this.loading = false));
                return;
            }

            this.loading = true;
            this.$http.post('contas-pagar-receber', this.form).then(resp => {
                const data = resp.data;
                if (data.type == 'warning') {
                    this.notify(data.msg, 'warning');
                    return;
                }
                this.$emit('onCadastro');
                this.notify('Salvo com sucesso!');
            }).catch(() => {
                this.notify('Verifique sua conexão com a internet', 'warning');
            }).finally(() => (this.loading = false));
        },

        onClienteUpdate(cliente) {
            this.form.cliente_id = cliente.id;
        },

        onChangePedido() {
            this.$http.get(`pedidos/${this.form.pedido_id}`).then(resp => {
                this.form.cliente = resp.data?.cliente || null;
                this.$forceUpdate();
            }).catch(() => {
                this.notify('Verifique sua conexão com a internet', 'warning');
            }).finally(() => (this.loading = false));
        },
    },
}
</script>

<style scoped>
.v-card {
    padding: 0 15px 0 15px;
}
</style>
